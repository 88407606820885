import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import React, { useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import capitoLogo from '../../assets/web.svg';
import './SmartBanner.scss';
import StarRating from './StarRating';

const SmartBanner = () => {
  const location = useLocation();

  //const appRedirectHref = `exp://192.168.0.80:19000/--${location.pathname}`; //for expo testing
  // eslint-disable-next-line max-len
  const appRedirectHref: string = `${process.env.REACT_APP_CAPITO_APP_SCHEME}:/${location.pathname}/`; // for production
  const [downloadHref, setDownloadHref] = useState<string | undefined>(
    undefined,
  );

  const [openLinkClicked, setOpenLinkClicked] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (openLinkClicked) {
        setOpenLinkClicked(false);
        var now = new Date().valueOf();
        setTimeout(function() {
          if (downloadHref) {
            if (new Date().valueOf() - now > 3020) return;
            window.location.href = downloadHref;
          }
        }, 3000);
        window.location.href = appRedirectHref;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openLinkClicked, window]);

  useEffect(() => {
    if (location && location.pathname) {
      if (isAndroid) {
        setDownloadHref(
          // eslint-disable-next-line max-len
          `https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=${process.env.REACT_APP_CAPITO_APP_ANDROID_APP_ID}`,
        );
      } else if (isIOS) {
        setDownloadHref(
          `https://apps.apple.com/at/app/capito-app/id${process.env.REACT_APP_CAPITO_APP_IOS_APP_ID}`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <I18n>
      {({ i18n }) => (
        <>
          {isAndroid || isIOS ? (
            <div className="smartbanner" id="smartabanner">
              <div className="smartbanner-container">
                <div className="smartbanner-logo-info-container" aria-hidden>
                  <div className="smartbanner-logo">
                    <img src={capitoLogo} alt="capito Logo" />
                  </div>
                  <div className="smartbanner-info">
                    <div className="smartbanner-title">capito App</div>
                    <div className="smartbanner-subtitle">
                      <StarRating />
                    </div>
                    <div className="smartbanner-subtitle">
                      {isAndroid ? 'Google Play Store' : 'App Store'}
                    </div>
                  </div>
                </div>
                <div className="smartbanner-button-container">
                  <button
                    onClick={() => {
                      setOpenLinkClicked(true);
                    }}
                    className="smartbanner-button"
                    aria-label={`${i18n._(
                      t`open the document with the capito App`,
                    )}`}
                  >
                    <span className="smartbanner-button-text" aria-hidden>
                      {`${i18n._(t`open App`)}`}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </I18n>
  );
};

export default SmartBanner;
