import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import React from 'react';
import './StarRating.scss';

const StarRating = () => {
  const ratingBeforePointNumber = '4';
  const ratingAfterPointNumber = '5';

  const renderStar = (full: boolean) => (
    <svg className="c-star active" viewBox="0 0 32 32">
      {full ? (
        <use xlinkHref="#star"></use>
      ) : (
        <use xlinkHref="#star" fill="url(#half)"></use>
      )}
    </svg>
  );

  return (
    <I18n>
      {({ i18n }) => (
        <div
          className="star-rating"
          aria-label={`${i18n._(
            t`The capito App is rated with`,
          )} ${ratingBeforePointNumber}${i18n._(
            t`--commaSeparator`,
          )}${ratingAfterPointNumber} ${i18n._(t`of 5 stars`)}.`}
        >
          <svg
            className="svgReference"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <defs>
              <linearGradient id="half" x1="0" x2="100%" y1="0" y2="0">
                <stop offset="50%" stopColor="#555"></stop>
                <stop offset="50%" stopColor="#efefef"></stop>
              </linearGradient>

              <symbol
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                id="star"
              >
                {/* eslint-disable-next-line max-len */}
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
              </symbol>
            </defs>
          </svg>

          {renderStar(true)}
          {renderStar(true)}
          {renderStar(true)}
          {renderStar(true)}
          {renderStar(false)}
          <span className="star-rating-text" aria-hidden>
            {ratingBeforePointNumber}
            {i18n._(t`--commaSeparator`)}
            {ratingAfterPointNumber}
          </span>
        </div>
      )}
    </I18n>
  );
};

export default StarRating;
