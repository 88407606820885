import pathToRegexp from 'path-to-regexp';
import { CEFRCode } from '../value-objects/CEFRCode';
import { IETFTag } from '../value-objects/IETFTag';

export default class PathCreator {
  static toContent(
    id: string,
    ietfTag: IETFTag,
    cefrCode: CEFRCode,
    params: any,
  ): string {
    const toPath = pathToRegexp.compile('/content/:id/:ietfTag/:cefrCode');
    return toPath({
      ...params,
      id: id,
      ietfTag: ietfTag,
      cefrCode: cefrCode,
    });
  }

  static toContentViaId(id: string): string {
    const toPath = pathToRegexp.compile('/content/:id');
    return toPath({
      id: id,
    });
  }
}
