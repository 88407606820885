import React, { useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import CloseableBannerWrapper from '../SmartBanner/CloseableBannerWrapper';
import SmartBanner from '../SmartBanner/SmartBanner';
import './TopBarWrapper.scss';

interface ComponentProps {
  children: any;
}

function TopBarWrapper(props: ComponentProps) {
  const [showSmartBanner, setShowSmartBanner] = useState<boolean>(true);

  const renderSmartBanner = () => (
    <>
      {isAndroid || isIOS ? (
        <CloseableBannerWrapper
          show={showSmartBanner}
          onDismiss={() => setShowSmartBanner(false)}
        >
          <SmartBanner />
        </CloseableBannerWrapper>
      ) : null}
    </>
  );

  return (
    <>
      <header className="top-bar-wrapper">
        {renderSmartBanner()}
        {props.children}
      </header>
      {showSmartBanner ? <div className="smart-banner-height-fix" /> : null}
    </>
  );
}

export default TopBarWrapper;
