import Author from './Author';
import Metadata from './Metadata';
import Level from './Level';
import { CEFRCode } from '../value-objects/CEFRCode';
import Styling from './Styling';

export default class Translation {
  constructor(
    readonly author: Author,
    readonly metadata: Metadata,
    readonly styling: Styling,
    readonly levels: Map<CEFRCode, Level>,
    readonly signLanguage?: string,
    readonly audioFile?: string,
  ) {}

  static empty(): Translation {
    return new Translation(
      Author.empty(),
      Metadata.empty(),
      Styling.empty(),
      new Map(),
      undefined,
      undefined,
    );
  }

  public containsLevel(code: CEFRCode): boolean {
    const keys = Array.from(this.levels.keys());
    return keys.includes(code);
  }

  public getLevel(code: CEFRCode): Level {
    if (this.containsLevel(code)) {
      const t = this.levels.get(code);
      if (t !== undefined) {
        return t;
      }
    }
    return Level.empty();
  }

  public getDefaultCEFRCode(): CEFRCode {
    if (this.containsLevel(CEFRCode.A1)) {
      return CEFRCode.A1;
    }
    if (this.containsLevel(CEFRCode.A2)) {
      return CEFRCode.A2;
    }
    if (this.containsLevel(CEFRCode.B1)) {
      return CEFRCode.B1;
    }
    return CEFRCode.Original;
  }

  public getDefaultLevel(): Level {
    return this.getLevel(this.getDefaultCEFRCode());
  }

  get levelsAvailable(): CEFRCode[] {
    return Array.from(this.levels.keys());
  }

  isEmpty() {
    return this.levels.size < 1;
  }
}
