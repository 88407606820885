import React from 'react';
import './CloseableBannerWrapper.scss';

interface ComponentProps {
  show: boolean;
  onDismiss?: () => void;
  children?: any;
}

const CloseableBannerWrapper = (props: ComponentProps) => {
  const { show, onDismiss, children } = props;

  const renderCloseButton = () => {
    return (
      <button onClick={onDismiss} className="close-button" aria-hidden>
        <div className="close-icon-container">
          <span className="close-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 15 15"
              enableBackground="new 0 0 15 15"
            >
              <line
                x1="0"
                y1="0"
                x2="15"
                y2="15"
                stroke="#666"
                strokeWidth="1"
                strokeLinecap="round"
                strokeMiterlimit="10"
              ></line>
              <line
                x1="15"
                y1="0"
                x2="0"
                y2="15"
                stroke="#666"
                strokeWidth="1"
                strokeLinecap="round"
                strokeMiterlimit="10"
              ></line>
            </svg>
          </span>
        </div>
      </button>
    );
  };

  if (show) {
    return (
      <div className="bannerContainer">
        {renderCloseButton()}
        {children}
      </div>
    );
  }
  return null;
};

export default CloseableBannerWrapper;
