/* eslint-disable object-curly-newline */
import {
  ContentState,
  initialState as contentInitialState,
} from './modules/content/state';
import {
  initialState as systemInitialState,
  SystemState,
} from './modules/system/state';

export default interface ApplicationState {
  system: SystemState;
  content: ContentState;
}

export const initialState: ApplicationState = {
  system: systemInitialState,
  content: contentInitialState,
};
