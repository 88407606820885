/* eslint-disable import/named */
import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import ListItem from '../../components/ListItem';
import ReturnTopBar from '../../components/TopBar/ReturnTopBar';
import { IETFTag } from '../../logic/value-objects/IETFTag';
import { setLanguage as setLanguageAction } from '../../store/modules/system/actions';
import ApplicationState from '../../store/state';
import wrapperStyle from './Content.module.scss';
import style from './Settings.module.scss';

interface ComponentProps extends RouteComponentProps {
  onDismiss: () => void;
}

interface StateProps {
  selected: IETFTag;
}

interface ActionProps {
  setLanguage: (language: IETFTag) => void;
}

type AllProps = ComponentProps & StateProps & ActionProps;

const mapStateToProps = (state: ApplicationState): StateProps => ({
  selected: state.system.language,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): ActionProps => ({
  setLanguage: (language: IETFTag) => {
    dispatch(setLanguageAction(language));
  },
});

const Settings = (props: AllProps) => {
  const { selected, onDismiss, setLanguage } = props;

  const languages = [IETFTag.German, IETFTag.English].filter(
    (language: IETFTag) => language !== selected,
  );

  return (
    <Fragment>
      <I18n>
        {({ i18n }) => (
          <ReturnTopBar
            title={i18n._(t`Settings`)}
            onBack={() => onDismiss()}
          />
        )}
      </I18n>
      <div className={wrapperStyle.appContentWrapper}>
        <div className={style.SettingsContainer}>
          <div className="capito-app-content">
            <h2>
              <Trans>User Interface Language</Trans>
            </h2>
            <h3 aria-hidden="true">
              <Trans>Currently selected</Trans>
            </h3>
            <I18n>
              {({ i18n }) => (
                <ListItem
                  isSelected={true}
                  title={i18n._(selected.toString())}
                  subtitle={i18n._(selected.toLocalString())}
                  imageSrc={selected.getFlag()}
                  imageAlt={i18n._(t`Flag of ${selected.toString()}`)}
                />
              )}
            </I18n>
            <h3 aria-hidden="true">
              <Trans>Also available</Trans>
            </h3>
            <I18n>
              {({ i18n }) => (
                <ul className={style.list}>
                  {languages.map((tag: IETFTag) => (
                    <li className={style.listItem} key={tag.toString()}>
                      <ListItem
                        isSelected={false}
                        title={i18n._(tag.toString())}
                        subtitle={i18n._(tag.toLocalString())}
                        imageSrc={tag.getFlag()}
                        imageAlt={i18n._(t`Flag of ${tag.toString()}`)}
                        onClick={() => {
                          setLanguage(tag);
                        }}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </I18n>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings),
);
