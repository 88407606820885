import { Trans } from '@lingui/macro';
import React from 'react';
import capitoLogo from '../../assets/web.svg';
import './CapitoLogo.scss';

const CapitoLogo = () => {
  return (
    <div className="capito-logo-container">
      <img src={capitoLogo} alt="capito Logo" />
      <div className="easy-reading-text">
        <Trans>Easy Reading by</Trans> capito
      </div>
    </div>
  );
};

export default CapitoLogo;
