/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import React from 'react';
import { Link } from 'react-router-dom';
import FullscreenLoading from '../../components/loading/LoadingFullscreen';
import Level from '../../logic/models/Level';
import { CEFRCode } from '../../logic/value-objects/CEFRCode';
import { IETFTag } from '../../logic/value-objects/IETFTag';
import KeyValuePair from '../../logic/value-objects/KeyValuePair';
import '../../styling/capito.scss';
import style from './ContentSections.module.scss';

interface Props {
  isLoading: boolean;
  level: KeyValuePair<CEFRCode, Level>;
  sections: string;
  cefrCode: CEFRCode;
  ietfTag: IETFTag;
  onRedirect: (redirectTo: string) => void;
}

const ContentSections = (props: Props) => {
  const {
    isLoading,
    level,
    sections,
    cefrCode,
    ietfTag,
    /*, onRedirect */
  } = props;

  const productiveBaseURL = 'https://app.capito.eu/content/';
  const testingBaseURL = 'https://testing.app.capito.eu/content/';

  const isInternalLink = (href: string): boolean => {
    return (
      href.startsWith(productiveBaseURL) || href.startsWith(testingBaseURL)
    );
  };

  const getInternalLinkReference = (href: string): string => {
    let baseURL = '';
    if (href.startsWith(productiveBaseURL)) {
      baseURL = productiveBaseURL;
    } else if (href.startsWith(testingBaseURL)) {
      baseURL = testingBaseURL;
    }

    const hrefId: string = href.replace(baseURL, '').split('/')[0];
    return `/content/${hrefId}/${ietfTag}/${cefrCode}`;
  };

  if (isLoading === undefined || isLoading) {
    return <FullscreenLoading />;
  }

  function parseWithInternalLinks(text: string) {
    const options: HTMLReactParserOptions = {
      replace: ({ name, attribs, children }: any) => {
        if (name === 'a' && attribs.href && isInternalLink(attribs.href)) {
          return (
            <Link to={getInternalLinkReference(attribs.href)}>
              {domToReact(children)}
            </Link>
          );
        }
        if (name === 'img' && attribs.alt === undefined) {
          delete Object.assign(attribs, {
            ['className']: attribs['class'],
          })['class'];
          return <img {...attribs} alt="" />;
          //return <img ...attribs />;
        }
      },
    };

    return parse(text, options);
  }

  return (
    <article className={style.main} lang={ietfTag.getLanguageCode()}>
      <div className="content-wrapper">
        <div className={'capito-app-content'}>
          <div
            className={'language-level-' + level.key}
            role="group"
            tabIndex={0}
          >
            {parseWithInternalLinks(sections)}
          </div>
        </div>
      </div>
    </article>
  );
};

export default ContentSections;
