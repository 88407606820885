import { ToolBarAction } from '../../../components/ToolBar';
import Content from '../../../logic/models/Content';
import Level from '../../../logic/models/Level';
import Translation from '../../../logic/models/Translation';
import { CEFRCode } from '../../../logic/value-objects/CEFRCode';
import { IETFTag } from '../../../logic/value-objects/IETFTag';
import KeyValuePair from '../../../logic/value-objects/KeyValuePair';

export const initialState: ContentState = {
  raw: Content.empty(),
  translation: new KeyValuePair(IETFTag.Undefined, Translation.empty()),
  level: new KeyValuePair(CEFRCode.Undefined, Level.empty()),
  actions: [ToolBarAction.Settings],
  footerBarColor: undefined,
};

export interface ContentState {
  raw: Content;
  translation: KeyValuePair<IETFTag, Translation>;
  level: KeyValuePair<CEFRCode, Level>;
  actions: ToolBarAction[];
  footerBarColor?: string;
}
