/* eslint-disable import/named */
import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { colors } from '../logic/constants/colors';
import { CEFRCode } from '../logic/value-objects/CEFRCode';
import { setLevel } from '../store/modules/content/actions';
import ApplicationState from '../store/state';
import './LanguageLevelBar.scss';

interface ComponentProps {}

interface StateProps {
  levels: CEFRCode[];
  code: CEFRCode;
  footerBarColor?: string;
}

interface ActionProps {
  setLevel: (cefrCode: CEFRCode) => void;
}

type AllProps = ComponentProps & StateProps & ActionProps;

const mapStateToProps = (state: ApplicationState): StateProps => ({
  code: state.content.level.key,
  levels: state.content.translation.value.levelsAvailable,
  footerBarColor: state.content.footerBarColor,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): ActionProps => {
  return {
    setLevel: cefrCode => dispatch(setLevel(cefrCode)),
  };
};

class LanguageLevelBar extends Component<AllProps, {}> {
  handleOnClick = (level: CEFRCode) => {
    if (this.props.levels.includes(level)) {
      return this.props.setLevel(level);
    }
  };

  renderLanguageLevelButton = (languageLevel: CEFRCode, label: string) => {
    console.log('this.props.footerBarColor: ', this.props.footerBarColor);

    const brandingColor = this.props.footerBarColor
      ? this.props.footerBarColor
      : colors.textOnLight;
    const textColorStyle = {
      color: brandingColor,
      borderBottomColor: brandingColor,
    };

    const hasLanguageLevel = this.props.levels.includes(languageLevel);
    const isSelected = this.props.code === languageLevel;

    const content = (
      <div aria-hidden={hasLanguageLevel ? 'false' : 'true'}>
        <div
          className={`cai ${
            hasLanguageLevel
              ? `cai-ll-${languageLevel.toString().toLocaleLowerCase()}`
              : `cai-ll-${languageLevel.toString().toLocaleLowerCase()}-na`
          }`}
        />
        <div>{label}</div>
      </div>
    );

    if (hasLanguageLevel) {
      return (
        <button
          aria-label={label}
          onClick={() => this.handleOnClick(languageLevel)}
          className={`language-level-item ${isSelected ? 'selected' : ''}`}
          style={textColorStyle}
        >
          {content}
        </button>
      );
    } else {
      return (
        <div
          className={`language-level-item ${
            hasLanguageLevel ? '' : 'unavailable'
          }`}
          style={textColorStyle}
        >
          {content}
        </div>
      );
    }
  };

  render() {
    return (
      <nav className="language-level-bar">
        <I18n>
          {({ i18n }) => (
            <div className="tabs">
              {this.renderLanguageLevelButton(CEFRCode.A1, i18n._(t`A1`))}
              {this.renderLanguageLevelButton(CEFRCode.A2, i18n._(t`A2`))}
              {this.renderLanguageLevelButton(CEFRCode.B1, i18n._(t`B1`))}
              {this.renderLanguageLevelButton(
                CEFRCode.Original,
                i18n._(t`Original`),
              )}
            </div>
          )}
        </I18n>
      </nav>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageLevelBar);
