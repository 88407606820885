export const SET_IS_LOADING = 'SET_IS_LOADING';
export type SET_IS_LOADING = typeof SET_IS_LOADING;

export const SET_LANGUAGE = 'SET_LANGUAGE';
export type SET_LANGUAGE = typeof SET_LANGUAGE;

export const SET_ERROR = 'SET_ERROR';
export type SET_ERROR = typeof SET_ERROR;

export const CLEAR_ERROR = 'CLEAR_ERROR';
export type CLEAR_ERROR = typeof CLEAR_ERROR;
