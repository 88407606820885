export class CEFRCode {
  static readonly A1 = new CEFRCode('a1');
  static readonly A2 = new CEFRCode('a2');
  static readonly B1 = new CEFRCode('b1');
  static readonly Original = new CEFRCode('original');
  static readonly Unknown = new CEFRCode('unknown');
  static readonly Undefined = new CEFRCode('undefined');

  private constructor(private value: string) {}

  static from(string: string) {
    switch (string.toLowerCase()) {
      case 'a1':
        return this.A1;
      case 'a2':
        return this.A2;
      case 'b1':
        return this.B1;
      case 'original':
        return this.Original;
      default:
        return this.Unknown;
    }
  }

  public toString = (): string => this.value;
}
