import axios from 'axios';
import { Config } from '../../config/configuration';
import Author from '../models/Author';
import Content from '../models/Content';
import Level from '../models/Level';
import Metadata from '../models/Metadata';
import Section from '../models/Section';
import Styling from '../models/Styling';
import Translation from '../models/Translation';
import { isLocalhost } from '../utilities/testing.utilities';
import { CEFRCode } from '../value-objects/CEFRCode';
import { IETFTag } from '../value-objects/IETFTag';

class ApiContent {
  reference: string = '';
  uuid: string = '';
  // isDefault: boolean = false;
  priority: number = 0;
  // isShareable: boolean = false;
  qrCode: string = '';
  publishedOn: string = '';
  translations: ApiTranslation[] = [];
}

class ApiTranslation {
  author: ApiAuthor = new ApiAuthor();
  metadata: ApiMetadata = new ApiMetadata();
  styling: ApiStyling = new ApiStyling();
  levels: ApiLevel[] = [];
  signLanguage?: string;
  audioFile?: string;
  ietfTag: string = '';
}

class ApiAuthor {
  name: string = '';
  biography: string = '';
}

enum ApiCefrCode {
  A1 = 'a1',
  A2 = 'a2',
  B1 = 'b1',
  Original = 'original',
  Undefined = 'undefined',
}

class ApiLevel {
  cefrCode: ApiCefrCode = ApiCefrCode.Undefined;
  sections: ApiSection[] = [];
}

class ApiSection {
  index: number = -1;
  // nestingLevel: number = 0;
  title: string = '';
  content: string = '';
}

class ApiMetadata {
  title: string = '';
  subtitle: string = '';
  description: string = '';
  summary: string = '';
}

class ApiStyling {
  stylesheet: string = '';
  color: string = '';
  thumbnail: string = '';
}

export default class LegacyApi {
  private static authKey = new Config().password;
  private static remoteContentUrl = new Config().host;

  static async download(id: string): Promise<Content> {
    const contentUrl = `${this.remoteContentUrl}${id}?key=${this.authKey}`;

    let remoteContent;
    if (isLocalhost) {
      console.log('Localhost (using proxy to disable CORS): for', contentUrl);
      remoteContent = (
        await axios.get('/forward', {
          headers: {
            'X-Forward': contentUrl,
          },
        })
      ).data as ApiContent;
    } else {
      remoteContent = (await axios.get(contentUrl)).data as ApiContent;
    }

    return new Content(id, LegacyApi.generateTranslations(remoteContent));
  }

  static generateTranslations(
    remoteContent: ApiContent,
  ): Map<IETFTag, Translation> {
    const map = new Map();

    remoteContent.translations.forEach(it => {
      map.set(
        IETFTag.from(it.ietfTag),
        new Translation(
          new Author(it.author.name, it.styling.thumbnail, it.author.biography),
          new Metadata(
            it.metadata.title,
            new Date(remoteContent.publishedOn.replace('+0000', 'Z')),
            it.metadata.summary,
            it.metadata.description,
            remoteContent.qrCode,
          ),
          new Styling(
            it.styling.stylesheet.replace(
              /.capito-document/gi,
              '.capito-app-content',
            ),
            it.styling.color,
            true,
          ),
          LegacyApi.generateLevels(it.levels),
          it.signLanguage,
          it.audioFile,
        ),
      );
    });

    return map;
  }

  static generateLevels(remoteLevels: ApiLevel[]): Map<CEFRCode, Level> {
    const map = new Map();

    remoteLevels.forEach(it => {
      map.set(
        CEFRCode.from(it.cefrCode),
        new Level(LegacyApi.generateSections(it.sections)),
      );
    });

    return map;
  }

  static generateSections(remoteSections: ApiSection[]): Section[] {
    const list: Section[] = [];

    remoteSections.forEach(it => {
      list.push(new Section(it.content.replace(/<br\s*\/*>/gi, ' '), it.index));
    });

    return list;
  }
}
