import { IETFTag } from '../value-objects/IETFTag';
import Translation from './Translation';

export default class Content {
  constructor(
    readonly id: string,
    readonly translations: Map<IETFTag, Translation>,
  ) {}

  public static empty(): Content {
    return new Content('', new Map());
  }

  public containsTranslation(tag: IETFTag): boolean {
    const keys = Array.from(this.translations.keys());
    return keys.includes(tag);
  }

  public getTranslation(tag: IETFTag): Translation {
    if (this.containsTranslation(tag)) {
      const t = this.translations.get(tag);
      if (t !== undefined) {
        return t;
      }
    }
    return Translation.empty();
  }

  public getDefaultIETFTag(): IETFTag {
    if (this.containsTranslation(IETFTag.German)) {
      return IETFTag.German;
    }
    if (this.containsTranslation(IETFTag.English)) {
      return IETFTag.English;
    }
    return IETFTag.Unknown;
  }

  public isEmpty(): boolean {
    return this.id === '';
  }

  public get translationsAvailable(): IETFTag[] {
    return Array.from(this.translations.keys());
  }
}
