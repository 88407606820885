/* eslint-disable import/extensions */
/* eslint-disable object-curly-newline */
import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import ApplicationState from './store/state.js';

interface StateProps {
  language: string;
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
  language: state.system.language.toString().split('-')[0],
});

const ConfiguredHelmet = (props: StateProps) => (
  <Helmet
    htmlAttributes={{
      lang: props.language,
    }}
  >
    <title>capitoApp</title>
    <meta charSet="UTF-8" />
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta content="#761136" name="theme-color" />
    <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
    <meta content="" name="capitoApp" />
    <meta name="googlebot" content="noindex" />

    {/* this only works for iOS and Safari devices, and once dismissed, it is not shown again!!
      <meta
        name="apple-itunes-app"
        content={`app-id=${process.env.REACT_APP_CAPITO_APP_IOS_APP_ID}, app-argument=${location.pathname}`}
      ></meta> */}

    <link href={`${process.env.PUBLIC_URL}/favicon.ico`} rel="icon" />
    <link
      href={`${process.env.PUBLIC_URL}/logo192.png`}
      rel="apple-touch-icon"
    />
    <link href={`${process.env.PUBLIC_URL}/manifest.json`} rel="manifest" />
  </Helmet>
);

export default connect(mapStateToProps)(ConfiguredHelmet);
