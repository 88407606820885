import React, { Component } from 'react';
import style from './ListItem.module.scss';

interface ComponentProps {
  title: string;
  imageSrc?: string;
  imageAlt?: string;
  subtitle?: string;
  onClick?: () => void;
  isSelected?: boolean;
}

class ListItem extends Component<ComponentProps, {}> {
  render() {
    let image;
    if (this.props.imageSrc !== undefined) {
      image = (
        <div className={style.image}>
          <img src={this.props.imageSrc} alt={this.props.imageAlt} />
        </div>
      );
    }

    let subtitle;
    if (this.props.subtitle !== undefined) {
      subtitle = (
        <div className={style.subtitle}>
          <div>{this.props.subtitle}</div>
        </div>
      );
    }

    let onClick = () => {};
    if (this.props.onClick !== undefined) {
      onClick = this.props.onClick;
    }
    return (
      <button
        aria-checked={this.props.isSelected}
        role="checkbox"
        aria-label={this.props.title}
        className={style.listItem}
        onClick={() => onClick()}
      >
        {image}
        <div className={style.textWrapper}>
          <div className={style.title}>{this.props.title}</div>
          {subtitle}
        </div>
      </button>
    );
  }
}

export default ListItem;
