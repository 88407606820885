/* eslint-disable import/named */
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import LegacyApi from '../../../logic/api/LegacyApi';
import Content from '../../../logic/models/Content';
import { CEFRCode } from '../../../logic/value-objects/CEFRCode';
import { IETFTag } from '../../../logic/value-objects/IETFTag';
import ApplicationState from '../../state';
import { setIsLoading } from '../system/actions';
import * as constants from './constants';

export interface SetContent extends Action {
  type: constants.SET_CONTENT;
  content: Content;
  translation?: IETFTag;
  level?: CEFRCode;
}

export const setContent = (
  content: Content,
  translation?: IETFTag,
  level?: CEFRCode,
): SetContent => ({
  type: constants.SET_CONTENT,
  content,
  translation,
  level,
});

export interface SetTranslation extends Action {
  type: constants.SET_TRANSLATION;
  ietfTag: IETFTag;
  cefrCode?: CEFRCode;
}

export const setTranslation = (
  ietfTag: IETFTag,
  cefrCode?: CEFRCode,
): SetTranslation => ({
  type: constants.SET_TRANSLATION,
  ietfTag,
  cefrCode,
});

export interface SetLevel extends Action {
  type: constants.SET_LEVEL;
  cefrCode: CEFRCode;
}

export const setLevel = (cefrCode: CEFRCode): SetLevel => ({
  type: constants.SET_LEVEL,
  cefrCode,
});

export const fetchContentAsync = (
  id: string,
  translation?: IETFTag,
  level?: CEFRCode,
): ThunkAction<Promise<void>, {}, {}, Action> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, Action>,
    getState,
  ): Promise<void> => {
    const { content } = getState() as ApplicationState;
    if (!content.raw.isEmpty() && content.raw.id === id) {
      // console.log(
      //   "Content with ID '" + id + "' already loaded, skipping download...",
      // );
      dispatch(setContent(content.raw, translation, level));
      return;
    }
    dispatch(setIsLoading(true));
    //console.log("Fetching raw with ID '" + id + "' from server...");
    const contentFromApi = await LegacyApi.download(id);

    //console.log('Fetched raw! Updating ApplicationState');
    dispatch(setContent(contentFromApi, translation, level));
    dispatch(setIsLoading(false));
  };
};

export type ContentAction = SetContent | SetTranslation | SetLevel;
