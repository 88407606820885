import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import capitoLogo from '../../assets/web.svg';
import CapitoLogo from '../../components/CapitoLogo/CapitoLogo';
import ApplicationState from '../../store/state';
import LanguageLevelBar from '../LanguageLevelBar';
import './ContentTopBar.scss';
import TopBarWrapper from './TopBarWrapper';

interface ComponentProps {}

interface StateProps {
  logo: string;
  title: string;
  author: string;
  branding: string;
  isLoading: boolean;
}

type AllProps = ComponentProps & StateProps;

const mapStateToProps = (state: ApplicationState): StateProps => ({
  author: state.content.translation.value.author.name,
  branding: state.content.translation.value.styling.accentColor,
  title: state.content.translation.value.metadata.title,
  logo: state.content.translation.value.author.logo,
  isLoading: state.system.isLoading,
});

class InfoBar extends Component<AllProps, {}> {
  renderDetails() {
    return (
      <div className="info-bar-details">
        <input
          type="checkbox"
          className="info-bar-details-toggler"
          id="info-bar-details-toggler"
        />
        <label
          htmlFor="info-bar-details-toggler"
          className="info-bar-details-toggler-label"
        >
          <span className="info-bar-details-toggler-icon" />
        </label>
        <div className="info-bar-details-content">
          <div className="info-bar-by">
            <Trans>by</Trans>
          </div>
          <div className="info-bar-author no-break">{this.props.author}</div>
          <div className="info-bar-easy-reading">
            <img src={capitoLogo} alt="capito Logo" />
          </div>
        </div>
      </div>
    );
  }

  renderMain() {
    return (
      <div className="info-bar-main">
        <div className="info-bar-main-content">
          {this.renderMainAuthorBrandingLogo()}
          {this.renderMainAuthorBrandingText()}
          <CapitoLogo />
        </div>
      </div>
    );
  }

  renderMainAuthorBrandingText() {
    return (
      <div className="info-bar-main-text">
        <h1 className="info-bar-title no-break">{this.props.title}</h1>
        <h2 className="info-bar-by">
          <Trans>by</Trans>
        </h2>
        <div className="info-bar-author no-break">{this.props.author}</div>
      </div>
    );
  }

  renderMainAuthorBrandingLogo() {
    return (
      <I18n>
        {({ i18n }) => (
          <img
            className="info-bar-logo"
            src={this.props.logo}
            alt={`${i18n._(t`Logo of the Author`)} ${this.props.author}`}
          />
        )}
      </I18n>
    );
  }

  renderContentTopBar() {
    if (this.props.isLoading) {
      return (
        <header
          className="info-bar"
          // style={{
          //   borderColor: this.props.branding,
          // }}
        >
          <div className="info-bar-main">
            <div className="info-bar-main-content">
              <CapitoLogo />
            </div>
          </div>
        </header>
      );
    }

    return (
      <header
        className="info-bar"
        // style={{
        //   borderColor: this.props.branding,
        // }}
      >
        {this.renderMain()}
        {/*this.renderDetails()*/}
      </header>
    );
  }

  render() {
    return (
      <TopBarWrapper>
        {this.renderContentTopBar()}
        <LanguageLevelBar />
      </TopBarWrapper>
    );
  }
}

export default connect(mapStateToProps)(InfoBar);
