import { Trans } from '@lingui/macro';
import React from 'react';
import Spinner from './indicators/Spinner';
import style from './LoadingFullscreen.module.scss';

const LoadingFullScreen = () => (
  <div className={style.loadingWrapper}>
    <div className={style.loadingIndicator}>
      <Spinner />
    </div>
    <div className={style.loadingText}>
      <Trans>Loading...</Trans>
    </div>
  </div>
);

export default LoadingFullScreen;
