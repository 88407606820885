/* eslint-disable import/named */
import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import ListItem from '../../components/ListItem';
import FullscreenLoading from '../../components/loading/LoadingFullscreen';
import ReturnTopBar from '../../components/TopBar/ReturnTopBar';
import PathCreator from '../../logic/utilities/PathCreator';
import { CEFRCode } from '../../logic/value-objects/CEFRCode';
import { IETFTag } from '../../logic/value-objects/IETFTag';
import { fetchContentAsync } from '../../store/modules/content/actions';
import ApplicationState from '../../store/state';
import wrapperStyle from './Content.module.scss';
import style from './LanguageSelector.module.scss';

interface ComponentProps
  extends RouteComponentProps<{
    id: string;
    ietfTag: string;
  }> {
  onDismiss: () => void;
}

interface StateProps {
  accentColor: string;
  languages: IETFTag[];
  selected: IETFTag;
  selectedLevel: CEFRCode;
  isLoading: boolean;
}

type AllProps = ComponentProps & StateProps & ActionProps;

const mapStateToProps = (state: ApplicationState): StateProps => ({
  accentColor: state.content.translation.value.styling.accentColor,
  languages: state.content.raw.translationsAvailable,
  selected: state.content.translation.key,
  selectedLevel: state.content.level.key,
  isLoading: state.system.isLoading,
});

interface ActionProps {
  fetchContent: (id: string, ietfTag?: IETFTag) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): ActionProps => {
  return {
    fetchContent: async (id, ietfTag) =>
      await dispatch(fetchContentAsync(id, ietfTag)),
  };
};

const LanguageSelector = (props: AllProps) => {
  useEffect(() => {
    props.fetchContent(props.match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.isLoading) {
    return <FullscreenLoading />;
  }

  return (
    <Fragment>
      <I18n>
        {({ i18n }) => (
          <ReturnTopBar
            title={i18n._(t`Languages Available`)}
            accentColor={props.accentColor}
            onBack={() => props.onDismiss()}
          />
        )}
      </I18n>
      <div className={wrapperStyle.appContentWrapper}>
        <div className={style.LanguageSelectorContainer}>
          <div className="capito-app-content">
            <h2 aria-hidden="true">
              <Trans>Currently selected</Trans>
            </h2>
            <I18n>
              {({ i18n }) => (
                <ListItem
                  isSelected={true}
                  title={i18n._(props.selected.toString())}
                  subtitle={i18n._(props.selected.toLocalString())}
                  imageSrc={props.selected.getFlag()}
                  imageAlt={i18n._(t`Flag of ${props.selected.toString()}`)}
                  onClick={() => {
                    props.onDismiss();
                  }}
                />
              )}
            </I18n>
            <h2 aria-hidden="true">
              <Trans>Also available</Trans>
            </h2>
            <I18n>
              {({ i18n }) => (
                <ul className={style.list}>
                  {props.languages
                    .filter(tag => tag !== props.selected)
                    .map(tag => (
                      <li className={style.listItem} key={tag.toString()}>
                        <ListItem
                          isSelected={false}
                          title={i18n._(tag.toString())}
                          subtitle={i18n._(tag.toLocalString())}
                          imageSrc={tag.getFlag()}
                          imageAlt={i18n._(t`Flag of ${tag.toString()}`)}
                          onClick={() => {
                            props.history.push(
                              PathCreator.toContent(
                                props.match.params.id,
                                tag,
                                props.selectedLevel,
                                props.match.params,
                              ),
                            );
                            props.onDismiss();
                          }}
                        />
                      </li>
                    ))}
                </ul>
              )}
            </I18n>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LanguageSelector),
);
