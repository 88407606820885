import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import React, { useEffect, useRef } from 'react';
import CapitoLogo from '../CapitoLogo/CapitoLogo';
import './ReturnTopBar.scss';
import TopBarWrapper from './TopBarWrapper';

interface ComponentProps {
  title: string;
  accentColor?: string;
  onBack: () => void;
}

function ReturnTopBar(props: ComponentProps) {
  const focusRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusRef && focusRef.current) {
      focusRef.current.focus();
    }
  }, [focusRef]);

  return (
    <TopBarWrapper>
      <div className="app-bar">
        <div className="app-bar-main">
          <div className="app-bar-main-content">
            <div className="back-button-wrapper">
              <I18n>
                {({ i18n }) => (
                  <button
                    ref={focusRef}
                    onClick={() => props.onBack()}
                    aria-label={i18n._(t`Back`)}
                  >
                    <i className="fal fa-arrow-left" />
                  </button>
                )}
              </I18n>
            </div>
            <div className="app-bar-main-text">
              <h1 className="app-bar-title no-break">{props.title}</h1>
            </div>
            <CapitoLogo />
          </div>
        </div>
      </div>
    </TopBarWrapper>
  );
}

export default ReturnTopBar;
