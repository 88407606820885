export default class Author {
  constructor(
    readonly name: string,
    readonly logo: string,
    readonly biography: string,
  ) {}

  static empty(): Author {
    return new Author('', '', '');
  }
}
