/*eslint-disable*/ module.exports = {
  messages: {
    '--commaSeparator': '.',
    A1: 'A1',
    A2: 'A2',
    'About the author': 'About the author',
    'About the content': 'About the content',
    'Also available': 'Also available',
    B1: 'B1',
    Back: 'Return',
    Close: 'Close',
    Copied: 'Copied',
    'Copy Link': 'Copy Link',
    'Currently Selected': 'Currently Selected',
    'Currently selected': 'Currently selected',
    Description: 'Description',
    Details: 'Details',
    'Easy Reading by': 'Easy Reading by',
    'Flag of de-at': "Combination of Austria's & Germany's flag",
    'Flag of en-gb': 'Flag of Great Britain',
    'Flag of {0}': '-',
    Imprint: 'Imprint',
    Language: 'Language',
    'Languages Available': 'Languages Available',
    'Loading...': 'Loading...',
    'Logo of the Author': 'Logo of the Author',
    Name: 'Name',
    'Or click the button below to share using the link!':
      'Or click the button below to share using the link!',
    Original: 'Original',
    'Play Audio File': 'Audio',
    'Privacy Policy': 'Privacy Policy',
    'Published on': 'Published on',
    'Scan this QR-Code in the': 'Scan this QR-Code in the',
    Settings: 'Settings',
    Share: 'Share',
    'Sign Language': 'Sign Language',
    Summary: 'Summary',
    'Terms & Conditions': 'Terms & Conditions',
    'The capito App is rated with': 'The capito App is rated with',
    'User Interface Language': 'User Interface Language',
    'Written by': 'Written by',
    by: 'by',
    'de-at': 'German',
    'en-gb': 'English (Great Britain)',
    'es-es': 'Spanish',
    'fr-fr': 'French',
    'https://www.capito.eu/en/': 'https://www.capito.eu/en/',
    'https://www.capito.eu/en/capito-app/':
      'https://www.capito.eu/en/capito-app/',
    'https://www.capito.eu/en/imprint/': 'https://www.capito.eu/en/imprint/',
    'https://www.capito.eu/en/privacy-policy/':
      'https://www.capito.eu/en/privacy-policy/',
    'https://www.capito.eu/en/terms-and-conditions/':
      'https://www.capito.eu/en/terms-and-conditions/',
    'it-it': 'Italian',
    'of 5 stars': 'of 5 stars',
    'open App': 'open App',
    'open the document with the capito App':
      'open the document with the capito App',
    'pl-pl': 'Polish',
    'to view on your mobile device!': 'to view on your mobile device!',
  },
};
