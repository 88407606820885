import * as React from 'react';
// eslint-disable-next-line import/no-named-as-default
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ConfiguredHelmet from './ConfiguredHelmet';
import { store } from './store';
import './styling/_variables.scss';
import Localization from './views/Localization';

Sentry.init({
  dsn:
    'https://5af77f69c17e534ba20b4d4e61097bee@o377656.ingest.sentry.io/4506060257624064',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App = () => {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <ConfiguredHelmet />
          <Switch>
            <Route path="/:language" component={Localization} />
            <Route>
              <Redirect
                exact
                path="/"
                to="/content/8540ddcc-54b2-4b82-9fbf-98b6e2938116"
              />
            </Route>
          </Switch>
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default App;
