/* eslint-disable import/named */
import { DateFormat, t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import FullscreenLoading from '../../components/loading/LoadingFullscreen';
import ReturnTopBar from '../../components/TopBar/ReturnTopBar';
import { IETFTag } from '../../logic/value-objects/IETFTag';
import { fetchContentAsync } from '../../store/modules/content/actions';
import ApplicationState from '../../store/state';
import wrapperStyle from './Content.module.scss';
import style from './Details.module.scss';

interface ComponentProps
  extends RouteComponentProps<{
    id: string;
    ietfTag: string;
    language: string;
  }> {
  onDismiss: () => void;
}

interface StateProps {
  accentColor: string;
  publishedOn: Date;
  summary: string;
  description: string;
  name: string;
  biography: string;
  logo: string;
  isLoading: boolean;
}

type AllProps = ComponentProps & StateProps & ActionProps;

const mapStateToProps = (state: ApplicationState): StateProps => ({
  accentColor: state.content.translation.value.styling.accentColor,
  publishedOn: state.content.translation.value.metadata.publishedOn,
  summary: state.content.translation.value.metadata.summary,
  description: state.content.translation.value.metadata.description,
  name: state.content.translation.value.author.name,
  biography: state.content.translation.value.author.biography,
  logo: state.content.translation.value.author.logo,
  isLoading: state.system.isLoading,
});

interface ActionProps {
  fetchContent: (id: string, ietfTag?: IETFTag) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): ActionProps => {
  return {
    fetchContent: async (id, ietfTag) =>
      await dispatch(fetchContentAsync(id, ietfTag)),
  };
};

const Details = (props: AllProps) => {
  useEffect(() => {
    props.fetchContent(
      props.match.params.id,
      IETFTag.from(props.match.params.ietfTag),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.isLoading) {
    return <FullscreenLoading />;
  }

  return (
    <>
      <I18n>
        {({ i18n }) => (
          <ReturnTopBar
            title={i18n._(t`Details`)}
            accentColor={props.accentColor}
            onBack={() => props.onDismiss()}
          />
        )}
      </I18n>

      <div className={wrapperStyle.appContentWrapper}>
        <article className={style.detailsContainer}>
          <div className="capito-app-content">
            <h2>
              <Trans>About the content</Trans>
            </h2>
            <h3>
              <Trans>Published on</Trans>
            </h3>
            <DateFormat
              value={props.publishedOn}
              format={{
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }}
            />
            <h3>
              <Trans>Summary</Trans>
            </h3>
            <div>{props.summary}</div>
            {props.description && props.description.length > 0 ? (
              <>
                <h3>
                  <Trans>Description</Trans>
                </h3>
                <div>{props.description}</div>
              </>
            ) : null}
            <hr />
            <h2>
              <Trans>Written by</Trans>
            </h2>
            <div className={style.authorContainer}>
              <div className={style.authorText}>{props.name}</div>
              <div className={style.authorLogo}>
                <I18n>
                  {({ i18n }) => (
                    <img src={props.logo} alt={i18n._(t`Logo of the Author`)} />
                  )}
                </I18n>
              </div>
            </div>
          </div>
          <button
            style={{
              backgroundColor: 'white',
              borderColor: 'white',
              color: '#ccc',
            }}
            onClick={() => {
              throw 'TEST-ERROR';
            }}
          >
            sentry
          </button>
        </article>
      </div>
    </>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Details),
);
