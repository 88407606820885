/* eslint-disable object-curly-newline */
import { SystemAction } from './actions';
import {
  CLEAR_ERROR,
  SET_ERROR,
  SET_IS_LOADING,
  SET_LANGUAGE,
} from './constants';
import { initialState, SystemState } from './state';

export default (
  state: SystemState = initialState,
  action: SystemAction,
): SystemState => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
  }
  return state;
};
