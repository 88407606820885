import { Trans } from '@lingui/macro';
import React from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';

interface ComponentProps {
  title: string;
  show: boolean;
  onDismiss?: () => void;
  children?: any;
}

const Modal = (props: ComponentProps) => {
  const { title, show, onDismiss, children } = props;

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={
        onDismiss
        /* Function that will be run when the modal is requested
     to be closed (either by clicking on overlay or pressing ESC).
     Note: It is not called if isOpen is changed by other means. */
      }
      contentLabel={title}
      overlayClassName={'modalOverlay'}
      className={'modalContent'}
      ariaHideApp={true}
      shouldFocusAfterRender={true}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldReturnFocusAfterClose={true}
      role={'dialog'}
      preventScroll={false}
      aria={{
        labelledby: 'heading',
        describedby: 'full_description',
      }}
    >
      <div className={'modalHeader'}>{title}</div>
      <div className={'modalInnerContent'}>{children}</div>
      <div className={'modalFooter'}>
        <button onClick={onDismiss}>
          <Trans>Close</Trans> <i className="fal fa-times-circle" />
        </button>
      </div>
    </ReactModal>
  );
};

export default Modal;
