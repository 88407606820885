import german from '../../assets/flags/de-at.svg';
import english from '../../assets/flags/en-gb.svg';
import spanish from '../../assets/flags/es-es.svg';
import french from '../../assets/flags/fr-fr.svg';
import italian from '../../assets/flags/it-it.svg';
import polish from '../../assets/flags/pl-pl.svg';

export class IETFTag {
  static readonly German = new IETFTag('de-at', 'Deutsch', german);
  static readonly English = new IETFTag(
    'en-gb',
    'English (Great Britain)',
    english,
  );
  static readonly Italian = new IETFTag('it-it', 'Italiano', italian);
  static readonly Spanish = new IETFTag('es-es', 'Español', spanish);
  static readonly Polish = new IETFTag('pl-pl', 'Polski', polish);
  static readonly French = new IETFTag('fr-fr', 'Français', french);
  static readonly Unknown = new IETFTag('unknown', 'Unknown', german);
  static readonly Undefined = new IETFTag('undefined', 'Undefined', german);

  private constructor(
    private value: string,
    private localString: string,
    private flag: string,
  ) {}

  static from(string: string) {
    switch (string.toLowerCase()) {
      case 'de-at':
        return this.German;
      case 'en-gb':
        return this.English;
      case 'it-it':
        return this.Italian;
      case 'es-es':
        return this.Spanish;
      case 'pl-pl':
        return this.Polish;
      case 'fr-fr':
        return this.French;
      default:
        return this.Unknown;
    }
  }

  public getLanguageCode = (): string => {
    if (
      this.value === undefined ||
      this.value.length < 2 ||
      this.value === 'unknown' ||
      this.value === 'undefined'
    ) {
      return 'en';
    }
    return this.value.slice(0, 2);
  };

  public toString = (): string => this.value;

  public toLocalString = (): string => this.localString;

  public getFlag = (): string => this.flag;
}
