import { IETFTag } from '../value-objects/IETFTag';

export class LanguagePreference {
  static get(): IETFTag {
    const lang = navigator.language;
    if (lang.startsWith('de')) {
      return IETFTag.German;
    }
    return IETFTag.English;
  }
}
