export default class Styling {
  constructor(
    readonly stylesheet: string,
    readonly accentColor: string,
    readonly useBrandingColorInFooter: boolean,
  ) {}

  static empty(): Styling {
    return new Styling('', '', false);
  }
}
