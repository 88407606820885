/*eslint-disable*/ module.exports = {
  messages: {
    '--commaSeparator': ',',
    A1: 'A1',
    A2: 'A2',
    'About the author': 'Über den Author',
    'About the content': 'Über den Inhalt',
    'Also available': 'Verfügbar',
    B1: 'B1',
    Back: 'Zurück',
    Close: 'Schließen',
    Copied: 'Kopiert',
    'Copy Link': 'Link kopieren',
    'Currently selected': 'Ausgewählt',
    Description: 'Beschreibung',
    Details: 'Details',
    'Easy Reading by': 'Leicht Lesen von',
    'Flag of de-at': 'Flagge zusammengesetz aus Österreich & Deutschland',
    'Flag of en-gb': 'Flagge von Großbritanien',
    'Flag of {0}': '-',
    Imprint: 'Impressum',
    Language: 'Sprache',
    'Languages Available': 'Sprachen verfügbar',
    'Loading...': 'Wird geladen...',
    'Logo of the Author': 'Logo des Autors',
    Name: 'Name',
    'Or click the button below to share using the link!':
      'Oder klicken Sie auf die Schaltfläche unterhalb um den Link zu teilen!',
    Original: 'Original',
    'Play Audio File': 'Audio',
    'Privacy Policy': 'Datenschutz',
    'Published on': 'Veröffentlicht am',
    'Scan this QR-Code in the': 'Scannen Sie diesen QR-Code in der',
    Settings: 'Einstellungen',
    Share: 'Teilen',
    'Sign Language': 'Gebärdensprache',
    Summary: 'Zusammenfassung',
    'Terms & Conditions': 'Geschäftsbedingungen',
    'The capito App is rated with': 'Die capito App ist mit',
    'User Interface Language': 'Sprache der Benutzeroberfläche',
    'Written by': 'Geschrieben von',
    by: 'von',
    'de-at': 'Deutsch',
    'en-gb': 'Englisch (Großbritanien)',
    'es-es': 'Spanisch',
    'fr-fr': 'Französisch',
    'https://www.capito.eu/en/': 'https://www.capito.eu/',
    'https://www.capito.eu/en/capito-app/': 'https://www.capito.eu/capito-app/',
    'https://www.capito.eu/en/imprint/': 'https://www.capito.eu/impressum/',
    'https://www.capito.eu/en/privacy-policy/':
      'https://www.capito.eu/datenschutz/',
    'https://www.capito.eu/en/terms-and-conditions/':
      'https://www.capito.eu/allgemeine-geschaeftsbedingungen/',
    'it-it': 'Italienisch',
    'of 5 stars': 'von 5 Sternen bewertet',
    'open App': 'App öffnen',
    'open the document with the capito App':
      'Dieses Dokument mit der capito App öffnen',
    'pl-pl': 'Polnisch',
    'to view on your mobile device!':
      'um diesen Inhalt auf Ihrem Telefon zu sehen!',
  },
};
