/* eslint-disable import/named */
import { Action } from 'redux';
import Error from '../../../logic/models/Error';
import { IETFTag } from '../../../logic/value-objects/IETFTag';
import * as constants from './constants';

export interface SetIsLoading extends Action {
  type: constants.SET_IS_LOADING;
  isLoading: boolean;
}

export interface SetError extends Action {
  type: constants.SET_ERROR;
  error: Error;
}

export interface ClearError extends Action {
  type: constants.CLEAR_ERROR;
}

export interface SetLanguage extends Action {
  type: constants.SET_LANGUAGE;
  language: IETFTag;
}

export const setIsLoading = (isLoading: boolean): SetIsLoading => ({
  type: constants.SET_IS_LOADING,
  isLoading,
});

export const setError = (error: Error): SetError => ({
  type: constants.SET_ERROR,
  error,
});

export const clearError = (): ClearError => ({
  type: constants.CLEAR_ERROR,
});

export const setLanguage = (language: IETFTag): SetLanguage => ({
  type: constants.SET_LANGUAGE,
  language,
});

export type SystemAction = SetIsLoading | SetError | ClearError | SetLanguage;
