import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import React from 'react';
import { connect } from 'react-redux';
import { colors } from '../logic/constants/colors';
import ApplicationState from '../store/state';
import './ToolBar.scss';

interface StateProps {
  footerBarColor?: string;
}

interface ComponentProps {
  actions: ToolBarAction[];
  onAction(action: ToolBarAction): void;
}

type AllProps = ComponentProps & StateProps;

const mapStateToProps = (state: ApplicationState): StateProps => ({
  footerBarColor: state.content.footerBarColor,
});

export enum ToolBarAction {
  Details,
  Language,
  Share,
  SignLanguage,
  Settings,
  AudioFile,
}

const ToolBar = (props: AllProps) => {
  const brandingColor = props.footerBarColor
    ? props.footerBarColor
    : colors.textOnLight;
  const textColorStyle = {
    color: brandingColor,
  };

  const handleOnClick = (action: ToolBarAction) => {
    if (props.actions.includes(action)) {
      return props.onAction(action);
    }
  };

  const hasDetails = props.actions.includes(ToolBarAction.Details);
  const hasLanguage = props.actions.includes(ToolBarAction.Language);
  const hasShare = props.actions.includes(ToolBarAction.Share);
  const hasSignLanguage = props.actions.includes(ToolBarAction.SignLanguage);
  const hasSettings = props.actions.includes(ToolBarAction.Settings);
  const hasAudioFile = props.actions.includes(ToolBarAction.AudioFile);

  const renderButton = (
    exists: boolean,
    label: string,
    buttonClassName: string,
    iconClassName: string,
    action: ToolBarAction,
  ) => {
    if (!exists) return null;
    return (
      <button
        aria-label={label}
        className={buttonClassName + (exists ? '' : ' unavailable')}
        onClick={() => handleOnClick(action)}
        style={textColorStyle}
      >
        <i className={`fal ${iconClassName}`} />
        <div>{label}</div>
      </button>
    );
  };

  return (
    <menu type="toolbar" className="tool-bar">
      <I18n>
        {({ i18n }) => (
          <div className="tool-bar-item-container">
            {renderButton(
              hasDetails,
              i18n._(t`Details`),
              'details',
              'fa-info-circle',
              ToolBarAction.Details,
            )}
            {renderButton(
              hasLanguage,
              i18n._(t`Language`),
              'language',
              'fa-flag',
              ToolBarAction.Language,
            )}
            {renderButton(
              hasShare,
              i18n._(t`Share`),
              'share',
              'fa-share-alt',
              ToolBarAction.Share,
            )}
            {renderButton(
              hasSignLanguage,
              i18n._(t`Sign Language`),
              'sign-language',
              'fa-sign-language',
              ToolBarAction.SignLanguage,
            )}
            {renderButton(
              hasAudioFile,
              i18n._(t`Play Audio File`),
              'audio-file',
              'fa-headphones-alt',
              ToolBarAction.AudioFile,
            )}
            {renderButton(
              hasSettings,
              i18n._(t`Settings`),
              'settings',
              'fa-cog',
              ToolBarAction.Settings,
            )}
          </div>
        )}
      </I18n>
    </menu>
  );
};

export default connect(mapStateToProps)(ToolBar);
