/* eslint-disable import/extensions */
/* eslint-disable object-curly-newline */
import { I18nProvider } from '@lingui/react';
import React from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  // eslint-disable-next-line import/named
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import catalogDe from '../assets/locales/de/messages.js';
import catalogEn from '../assets/locales/en/messages.js';
import { FooterBar } from '../components/FooterBar';
import ApplicationState from '../store/state';
import Content from './content/Content';
import RedirectViaId from './content/RedirectViaId';
import RedirectViaIetfTag from './content/RedirectViaIetfTag';

const catalogs = {
  de: catalogDe,
  en: catalogEn,
};

interface ComponentProps extends RouteComponentProps {
  language: string;
}

interface StateProps {
  language: string;
  footerBarColor?: string;
}

type AllProps = ComponentProps & StateProps;

const mapStateToProps = (state: ApplicationState): StateProps => ({
  language: state.system.language.toString().split('-')[0],
  footerBarColor: state.content.footerBarColor,
});

const Localization = (props: AllProps) => {
  return (
    <I18nProvider language={props.language} catalogs={catalogs}>
      <Switch>
        <Route exact path={`/content`}>
          <Redirect
            to={`${props.match.url}/8540ddcc-54b2-4b82-9fbf-98b6e2938116`}
          />
        </Route>
        <Route exact path={`/content/:id`} component={RedirectViaId} />
        <Route
          exact
          path={`/content/:id/:ietfTag`}
          component={RedirectViaIetfTag}
        />
        <Route
          exact
          path={`/content/:id/:ietfTag/:cefrCode`}
          component={Content}
        />
        <Route>
          <Redirect to={`/content/8540ddcc-54b2-4b82-9fbf-98b6e2938116`} />
        </Route>
      </Switch>
      <FooterBar
        language={props.language}
        backgroundColor={props.footerBarColor}
      />
    </I18nProvider>
  );
};

export default withRouter(connect(mapStateToProps)(Localization));
