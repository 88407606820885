import { ToolBarAction } from '../../../components/ToolBar';
import { colors } from '../../../logic/constants/colors';
import Content from '../../../logic/models/Content';
import Level from '../../../logic/models/Level';
import Translation from '../../../logic/models/Translation';
import { CEFRCode } from '../../../logic/value-objects/CEFRCode';
import { IETFTag } from '../../../logic/value-objects/IETFTag';
import KeyValuePair from '../../../logic/value-objects/KeyValuePair';
import { ContentAction } from './actions';
import { SET_CONTENT, SET_LEVEL, SET_TRANSLATION } from './constants';
import { ContentState, initialState } from './state';

export default (
  state: ContentState = initialState,
  action: ContentAction,
): ContentState => {
  switch (action.type) {
    case SET_CONTENT:
      return setContent(
        state,
        action.content,
        action.translation,
        action.level,
      );
    case SET_TRANSLATION:
      return setTranslation(state, action.ietfTag, action.cefrCode);
    case SET_LEVEL:
      return setLevel(state, action.cefrCode);
    default:
      return state;
  }
};

function setContent(
  state: ContentState,
  content: Content,
  translation?: IETFTag,
  level?: CEFRCode,
): ContentState {
  if (
    !state.raw.isEmpty() &&
    state.raw.id === content.id &&
    state.translation.key === translation &&
    state.level.key === level
  ) {
    return state;
  }
  return setTranslation(
    {
      ...state,
      raw: content,
    },
    translation || state.translation.key,
    level,
  );
}

function setTranslation(
  state: ContentState,
  tag: IETFTag,
  level?: CEFRCode,
): ContentState {
  let translation: KeyValuePair<IETFTag, Translation>;
  let actions: ToolBarAction[];
  if (state.raw.isEmpty()) {
    translation = new KeyValuePair(IETFTag.Undefined, Translation.empty());
    actions = [ToolBarAction.Settings];
  } else {
    if (state.raw.containsTranslation(tag)) {
      translation = new KeyValuePair(tag, state.raw.getTranslation(tag));
    } else if (state.raw.containsTranslation(state.translation.key)) {
      translation = new KeyValuePair(
        state.translation.key,
        state.raw.getTranslation(state.translation.key),
      );
    } else {
      const defaultTag = state.raw.getDefaultIETFTag();
      translation = new KeyValuePair(
        defaultTag,
        state.raw.getTranslation(defaultTag),
      );
    }
  }
  actions = [
    ToolBarAction.Details,
    ...(state.raw.translations.size > 1 ? [ToolBarAction.Language] : []),
    ToolBarAction.Share,
    ...(typeof translation.value.signLanguage === 'string'
      ? [ToolBarAction.SignLanguage]
      : []),
    ...(typeof translation.value.audioFile === 'string'
      ? [ToolBarAction.AudioFile]
      : []),
    ToolBarAction.Settings,
  ];
  let footerBarColor = translation.value.styling.useBrandingColorInFooter
    ? translation.value.styling.accentColor
    : undefined;

  footerBarColor =
    footerBarColor === '#b70e0c' ? colors.capitoRed : footerBarColor; //ensure new capito red is used

  return setLevel(
    {
      ...state,
      translation,
      actions,
      footerBarColor,
    },
    level || state.level.key,
  );
}

function setLevel(state: ContentState, code: CEFRCode): ContentState {
  let level: KeyValuePair<CEFRCode, Level>;
  if (state.raw.isEmpty()) {
    level = new KeyValuePair(CEFRCode.Undefined, Level.empty());
  } else {
    if (state.translation.value.containsLevel(code)) {
      level = new KeyValuePair(code, state.translation.value.getLevel(code));
    } else if (state.translation.value.containsLevel(state.level.key)) {
      level = new KeyValuePair(
        state.level.key,
        state.translation.value.getLevel(state.level.key),
      );
    } else {
      const defaultCode = state.translation.value.getDefaultCEFRCode();
      level = new KeyValuePair(
        defaultCode,
        state.translation.value.getLevel(defaultCode),
      );
    }
  }
  return {
    ...state,
    level,
  };
}
