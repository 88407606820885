/* eslint-disable object-curly-newline */
/* eslint-disable import/named */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import LoadingFullscreen from '../../components/loading/LoadingFullscreen';
import ContentModel from '../../logic/models/Content';
import Level from '../../logic/models/Level';
import Translation from '../../logic/models/Translation';
import PathCreator from '../../logic/utilities/PathCreator';
import { CEFRCode } from '../../logic/value-objects/CEFRCode';
import { IETFTag } from '../../logic/value-objects/IETFTag';
import KeyValuePair from '../../logic/value-objects/KeyValuePair';
import {
  fetchContentAsync,
  setTranslation,
} from '../../store/modules/content/actions';
import ApplicationState from '../../store/state';

interface RouteProps
  extends RouteComponentProps<{
    id: string;
    ietfTag: string;
    language: string;
  }> {}

interface StoreProps {
  content: ContentModel;
  translation: KeyValuePair<IETFTag, Translation>;
  level: KeyValuePair<CEFRCode, Level>;
}

interface ActionProps {
  fetchContent: (id: string, ietfTag: IETFTag) => void;
  setTranslation: (ietfTag: IETFTag) => void;
}

const mapStateToProps = (state: ApplicationState): StoreProps => ({
  content: state.content.raw,
  translation: state.content.translation,
  level: state.content.level,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): ActionProps => ({
  fetchContent: async (id, ietfTag: IETFTag) =>
    dispatch(fetchContentAsync(id, ietfTag)),
  setTranslation: (ietfTag: IETFTag) => dispatch(setTranslation(ietfTag)),
});

type Props = RouteProps & ActionProps & StoreProps;

class RedirectViaIetfTag extends Component<Props, {}> {
  componentDidMount(): void {
    if (this.props.content.id !== this.props.match.params.id) {
      this.props.fetchContent(
        this.props.match.params.id,
        IETFTag.from(this.props.match.params.ietfTag),
      );
    } else {
      this.redirect();
    }
  }

  componentDidUpdate(): // prevProps: Readonly<Props>,
  // prevState: Readonly<{}>,
  // snapshot?: any,
  void {
    this.redirect();
  }

  private redirect = () => {
    if (
      this.props.translation.key.toString() !== this.props.match.params.ietfTag
    ) {
      this.props.setTranslation(IETFTag.from(this.props.match.params.ietfTag));
    } else {
      this.props.history.replace(
        PathCreator.toContent(
          this.props.content.id,
          this.props.translation.key,
          this.props.level.key,
          this.props.match.params,
        ),
      );
    }
  };

  render() {
    return <LoadingFullscreen />;
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RedirectViaIetfTag),
);
