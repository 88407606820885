import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import chromajs from 'chroma-js';
import React from 'react';
import capitoLogoOnDark from '../assets/web-inverse.svg';
import capitoLogoOnLight from '../assets/web.svg';
import { colors } from '../logic/constants/colors';
import './FooterBar.scss';

interface FooterProps {
  language: string;
  backgroundColor?: string;
}

export const FooterBar = ({ language, backgroundColor }: FooterProps) => {
  console.log('backgroundColor: ', backgroundColor);

  const c = backgroundColor || colors.capitoRed;

  console.log('backgroundColor: ', backgroundColor);
  console.log('c: ', c);

  const isBackgroundDark =
    chromajs.contrast(colors.textOnDark, c) >
    chromajs.contrast(colors.textOnLight, c);
  const textColor =
    chromajs.contrast(colors.capitoRed, c) > colors.wcagContrast
      ? colors.capitoRed
      : isBackgroundDark
      ? colors.textOnDark
      : colors.textOnLight;

  const backgroundColorStyle = {
    backgroundColor: c,
  };
  const textColorStyle = {
    color: textColor,
  };
  const icon = isBackgroundDark ? capitoLogoOnDark : capitoLogoOnLight;

  const renderFooterLink = (
    href: string,
    label: string | undefined,
    children?: JSX.Element,
  ) => {
    return (
      <a
        aria-label={label}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={textColorStyle}
      >
        {label}
        {children ? children : null}
      </a>
    );
  };

  return (
    <footer
      lang={language}
      className={'footer-bar ' + language}
      style={backgroundColorStyle}
    >
      <div className="content">
        <I18n>
          {({ i18n }) => (
            <>
              <div className="left">
                {renderFooterLink(
                  i18n._(t`https://www.capito.eu/en/imprint/`),
                  i18n._(t`Imprint`),
                )}
                {renderFooterLink(
                  i18n._(t`https://www.capito.eu/en/terms-and-conditions/`),
                  i18n._(t`Terms & Conditions`),
                )}
                {renderFooterLink(
                  i18n._(t`https://www.capito.eu/en/privacy-policy/`),
                  i18n._(t`Privacy Policy`),
                )}
              </div>
              <div className="right">
                {renderFooterLink(
                  i18n._(t`https://www.capito.eu/en/`),
                  undefined,
                  <>
                    <span>capito.eu</span>
                    <img src={icon} alt="capito logo" />
                  </>,
                )}
              </div>
            </>
          )}
        </I18n>
      </div>
    </footer>
  );
};
