import Error from '../../../logic/models/Error';
import { IETFTag } from '../../../logic/value-objects/IETFTag';
import { LanguagePreference } from '../../../logic/utilities/LanguagePreference';

export const initialState: SystemState = {
  error: undefined,
  isLoading: true,
  language: LanguagePreference.get(),
};

export interface SystemState {
  isLoading: boolean;
  error?: Error;
  language: IETFTag;
}
