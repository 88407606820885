export default class Metadata {
  constructor(
    readonly title: string,
    readonly publishedOn: Date,
    readonly summary: string,
    readonly description: string,
    readonly qrCode: string,
  ) {}

  static empty(): Metadata {
    return new Metadata('', new Date(), '', '', '');
  }
}
