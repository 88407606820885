/* eslint-disable object-curly-newline */
/* eslint-disable import/named */
import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import React, { Component, createRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  default as AudioPlayer,
  default as H5AudioPlayer,
  RHAP_UI,
} from 'react-h5-audio-player';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import Modal from '../../components/Modal';
import ToolBar, { ToolBarAction } from '../../components/ToolBar';
import ContentTopBar from '../../components/TopBar/ContentTopBar';
import ContentModel from '../../logic/models/Content';
import Level from '../../logic/models/Level';
import Translation from '../../logic/models/Translation';
import PathCreator from '../../logic/utilities/PathCreator';
import { CEFRCode } from '../../logic/value-objects/CEFRCode';
import { IETFTag } from '../../logic/value-objects/IETFTag';
import KeyValuePair from '../../logic/value-objects/KeyValuePair';
import {
  fetchContentAsync,
  setLevel,
  setTranslation,
} from '../../store/modules/content/actions';
import ApplicationState from '../../store/state';
//import FullscreenLoading from "../../components/loading/LoadingFullscreen";
import '../../styling/capito.scss';
import style from './Content.module.scss';
import ContentSections from './ContentSections';
import Details from './Details';
import LanguageSelector from './LanguageSelector';
import Settings from './Settings';

interface RouteProps
  extends RouteComponentProps<{
    id: string;
    ietfTag: string;
    cefrCode: string;
    language: string;
  }> {}

interface StoreProps {
  content: ContentModel;
  translation: KeyValuePair<IETFTag, Translation>;
  level: KeyValuePair<CEFRCode, Level>;
  actions: ToolBarAction[];
  isLoading: boolean;
}

interface ActionProps {
  fetchContent: (id: string, ietfTag?: IETFTag, cefrCode?: CEFRCode) => void;
  setTranslation: (ietfTag: IETFTag, cefrCode: CEFRCode) => void;
  setLevel: (cefrCode: CEFRCode) => void;
}

type Props = RouteProps & StoreProps & ActionProps;

const mapStateToProps = (state: ApplicationState): StoreProps => ({
  content: state.content.raw,
  isLoading: state.system.isLoading,
  translation: state.content.translation,
  level: state.content.level,
  actions: state.content.actions,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): ActionProps => {
  return {
    fetchContent: async (id, ietfTag, cefrCode) =>
      await dispatch(fetchContentAsync(id, ietfTag, cefrCode)),
    setTranslation: (ietfTag, cefrCode) =>
      dispatch(setTranslation(ietfTag, cefrCode)),
    setLevel: cefrCode => dispatch(setLevel(cefrCode)),
  };
};

interface ComponentState {
  showShareModal: boolean;
  showSettings: boolean;
  showLanguageSettings: boolean;
  showDetails: boolean;
  showAudioPlayer: boolean;
  linkCopied: boolean;
}

class Content extends Component<Props, ComponentState> {
  private playerRef = createRef<H5AudioPlayer>();

  constructor(props: Props) {
    super(props);

    this.state = {
      showShareModal: false,
      showSettings: false,
      showLanguageSettings: false,
      showDetails: false,
      showAudioPlayer: false,
      linkCopied: false,
    };

    this.loadContent();
  }

  private get content(): ContentModel {
    return this.props.content;
  }

  private get translation(): Translation {
    return this.props.translation.value;
  }

  private get level(): Level {
    return this.props.level.value;
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    // prevState: Readonly<{}>,
    // snapshot?: any,
  ): void {
    if (this.routeChanged(prevProps)) {
      this.updateContent(prevProps);
    }

    if (this.contentChanged(prevProps)) {
      this.updateRoute(prevProps);
    }
  }

  renderShareModal = (copyLink: any) => (
    <I18n>
      {({ i18n }) => (
        <Modal
          title={i18n._(t`Share`)}
          show={this.state.showShareModal}
          onDismiss={() =>
            this.setState({
              showShareModal: false,
              linkCopied: false,
            })
          }
        >
          <div className={style.qrCode}>
            <div>
              <span>
                <Trans>Scan this QR-Code in the</Trans>
              </span>

              <a
                aria-label={'capitoApp'}
                href={i18n._(t`https://www.capito.eu/en/capito-app/`)}
                rel="noopener noreferrer"
                target="_blank"
                className={style.i18nLink}
              >
                capitoApp
              </a>

              <Trans>to view on your mobile device!</Trans>
            </div>
            <img
              src={this.props.translation.value.metadata.qrCode}
              alt="QR-Code"
            />
          </div>
          <div className={style.copyLink}>
            <div>
              <Trans>Or click the button below to share using the link!</Trans>
            </div>
            <div>
              <CopyToClipboard
                text={window.location.href}
                onCopy={() =>
                  this.setState({
                    linkCopied: true,
                  })
                }
              >
                <button>{copyLink}</button>
              </CopyToClipboard>
            </div>
          </div>
        </Modal>
      )}
    </I18n>
  );

  renderAudioPlayerModal = () => (
    <I18n>
      {({ i18n }) => (
        <Modal
          title={i18n._(t`Play Audio File`)}
          show={this.state.showAudioPlayer}
          onDismiss={() => {
            this.setState({
              showAudioPlayer: false,
            });
            if (
              !this.playerRef.current ||
              !this.playerRef.current.audio.current
            ) {
              return;
            }
            const audioElement = this.playerRef.current.audio.current;
            audioElement.pause();
            audioElement.currentTime = 0;
          }}
        >
          <AudioPlayer
            preload="metadata"
            src={this.props.translation.value.audioFile}
            customControlsSection={[
              <div key="audioPlayerPreDiv"></div>,
              RHAP_UI.MAIN_CONTROLS,
              <div key="audioPlayerPostDiv"></div>,
            ]}
            ref={this.playerRef}
          />
        </Modal>
      )}
    </I18n>
  );

  render() {
    if (this.state.showSettings) {
      return (
        <Settings
          onDismiss={() =>
            this.setState({
              showSettings: false,
            })
          }
        />
      );
    }

    if (this.state.showDetails) {
      return (
        <Details
          onDismiss={() =>
            this.setState({
              showDetails: false,
            })
          }
        />
      );
    }

    if (this.state.showLanguageSettings) {
      return (
        <LanguageSelector
          onDismiss={() =>
            this.setState({
              showLanguageSettings: false,
            })
          }
        />
      );
    }

    let copyLink;
    if (this.state.linkCopied) {
      copyLink = (
        <span>
          <Trans>Copied</Trans> <i className="fal fa-check" />
        </span>
      );
    } else {
      copyLink = (
        <span>
          <Trans>Copy Link</Trans> <i className="fal fa-link" />
        </span>
      );
    }

    return (
      <>
        <style>{this.translation.styling.stylesheet}</style>
        {this.renderShareModal(copyLink)}
        {this.renderAudioPlayerModal()}

        <div className="contentWrapper">
          <ContentTopBar />
          <main>
            <ContentSections
              isLoading={this.props.isLoading}
              cefrCode={CEFRCode.from(this.props.match.params.cefrCode)}
              ietfTag={IETFTag.from(this.props.match.params.ietfTag)}
              level={this.props.level}
              sections={this.level.sections
                .sort((a, b) => a.index - b.index)
                .map(s => s.content)
                .join('')}
              onRedirect={this.handleRedirectTo}
            />
          </main>
        </div>
        <ToolBar actions={this.props.actions} onAction={this.onAction} />
      </>
    );
  }

  private handleRedirectTo = (redirectTo: string) => {
    this.props.history.push(redirectTo);
  };

  private onAction = (action: ToolBarAction) => {
    switch (action) {
      case ToolBarAction.Details:
        this.setState({
          showDetails: true,
        });
        break;
      case ToolBarAction.Language:
        this.setState({
          showLanguageSettings: true,
        });
        break;
      case ToolBarAction.Settings:
        this.setState({
          showSettings: true,
        });
        break;
      case ToolBarAction.Share:
        this.setState({
          showShareModal: true,
        });
        break;
      case ToolBarAction.SignLanguage:
        window.open(this.translation.signLanguage, '_blank');
        break;
      case ToolBarAction.AudioFile:
        this.setState({
          showAudioPlayer: true,
        });
        break;
    }
  };

  private loadContent() {
    this.props.fetchContent(
      this.props.match.params.id,
      IETFTag.from(this.props.match.params.ietfTag),
      CEFRCode.from(this.props.match.params.cefrCode),
    );
  }

  private routeChanged(prevProps: Readonly<Props>): boolean {
    const previousRouteMatchesContent = this.routeMatchesContent(prevProps);
    const currentRouteMatchesContent = this.routeMatchesContent(this.props);
    return previousRouteMatchesContent && !currentRouteMatchesContent;
  }

  private contentChanged(prevProps: Readonly<Props>): boolean {
    const previous = prevProps.match.params;
    const current = this.props.match.params;

    const routeIdMatches = previous.id === current.id;
    const routeTagMatches = previous.ietfTag === current.ietfTag;
    const routeCodeMatches = previous.cefrCode === current.cefrCode;

    const routeMatches = routeIdMatches && routeTagMatches && routeCodeMatches;

    const previousRouteMatchesContent = this.routeMatchesContent(prevProps);
    return routeMatches && !previousRouteMatchesContent;
  }

  private routeMatchesContent(props: Readonly<Props>): boolean {
    const params = props.match.params;

    const idMatches = params.id === this.props.content.id;
    const tagMatches = params.ietfTag === this.props.translation.key.toString();
    const codeMatches = params.cefrCode === this.props.level.key.toString();
    return idMatches && tagMatches && codeMatches;
  }

  private updateRoute(prevProps: Readonly<Props>) {
    const params = prevProps.match.params;
    const content = this.props.content;

    const tag = IETFTag.from(params.ietfTag);
    const code = CEFRCode.from(params.cefrCode);

    const previousTagValid = content.containsTranslation(tag);
    const previousCodeValid = content.getTranslation(tag).containsLevel(code);

    const previousRouteValid = previousTagValid && previousCodeValid;

    const routeMatchesContent = this.routeMatchesContent(prevProps);

    if (previousRouteValid) {
      return this.props.history.push(
        PathCreator.toContent(
          this.props.content.id,
          this.props.translation.key,
          this.props.level.key,
          this.props.match.params,
        ),
      );
    } else if (!routeMatchesContent) {
      return this.props.history.replace(
        PathCreator.toContent(
          this.props.content.id,
          this.props.translation.key,
          this.props.level.key,
          this.props.match.params,
        ),
      );
    } else {
      return this.props.history.goBack();
    }
  }

  private updateContent(prevProps: Readonly<Props>) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      return this.loadContent();
    }
    if (prevProps.match.params.ietfTag !== this.props.match.params.ietfTag) {
      return this.props.setTranslation(
        IETFTag.from(this.props.match.params.ietfTag),
        CEFRCode.from(this.props.match.params.cefrCode),
      );
    }
    if (prevProps.match.params.cefrCode !== this.props.match.params.cefrCode) {
      return this.props.setLevel(
        CEFRCode.from(this.props.match.params.cefrCode),
      );
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Content),
);
